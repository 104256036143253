const IS_PRODUCTION = import.meta.env.VITE_APP_ENVIRONMENT == 'production';
const IS_DEVELOPMENT = import.meta.env.VITE_APP_ENVIRONMENT == 'development';
const IS_LOCAL = import.meta.env.VITE_APP_ENVIRONMENT == 'local';

export const GOBAZAR_CONFIG = {
  API_URL: import.meta.env.VITE_APP_BASE_API,
  APP_NAME: 'GoBazar',
  GOOGLE_GTAG_ID: import.meta.env.VITE_APP_GTAG_ID,
  IS_DEBUGGING: !IS_PRODUCTION,
  IS_DEVELOPMENT,
  IS_LIVE: IS_PRODUCTION || IS_DEVELOPMENT,
  IS_LOCAL,
  IS_PRODUCTION,
  MOBILE_APPS: {
    SELLER: {
      APP_STORE: 'https://apps.apple.com/kz/app/gobazar-seller/id6505095431',
      PLAY_STORE: 'https://play.google.com/store/apps/details?id=io.gobazar.seller.kz&hl=ru',
    },
  },
  SELLER_URL: import.meta.env.VITE_APP_SELLER_URL || 'https://seller.gobazar.io',
  SITE_DOMAIN: import.meta.env.VITE_APP_SITE_DOMAIN || 'gobazar.io',
  SITE_URL: import.meta.env.VITE_APP_SITE_URL || 'https://gobazar.io',
};
